
import Email from "../../assets/email.svg";
import Phone from "../../assets/phone.svg";
import Instagram from "../../assets/instagram.svg";

export default function Contact() {
    return (
        <div style={{ padding: '32px 0px' }}>
            <div className="container">
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                    <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>CONTATO</h3>
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Entre em contato com a nossa equipe para tirar dúvidas sobre o evento ou a cidade:</span>
                    <div style={{ padding: '8px 0px' }}>
                        <img style={{ marginRight: '8px'}} alt="Email" src={Email} width={25}/>
                        <span style={{ fontSize: '18px' }}>wticifes2022@academico.ufs.br</span>
                    </div>
                    <div style={{ padding: '8px 0px' }}>
                        <img style={{ marginRight: '8px'}} alt="Telefone" src={Phone} width={25}/>
                        <span style={{ fontSize: '18px' }}>(79) 98117-8458</span>
                    </div>
                    <div style={{ padding: '8px 0px' }}>
                        <img style={{ marginRight: '8px'}} alt="Instagram" src={Instagram} width={25}/>
                        <span style={{ fontSize: '18px', textDecoration: 'none' }}>
                            <a style={{ textDecoration: 'none' }} href="https://www.instagram.com/wticifes2022">@wticifes2022</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}