import React from 'react';

export default function Presentation() {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className='container'>
        <div style={{ display: "flex", paddingTop: '60px', flexDirection: 'column', textAlign: 'justify' }}>
          <h3 style={{ color: '#25408F', marginBottom: '32px', fontWeight: 'bold' }}>APRESENTAÇÃO</h3>

          <p style={{ color: '#25408F' }}>
            O Workshop de Tecnologia de Informação e Comunicação das Instituições
            Federais de Ensino Superior do Brasil (WTICIFES) é um evento promovido pelo
            Colégio de Gestores de Tecnologia de Comunicação (CGTIC) das Instituições
            Federais de Ensino Superior (IFES) e ao longo das edições se consolidou na
            agenda dos gestores e técnicos de TIC dessas entidades.
          </p>
          <p>
            O WTICIFES é um evento específico, direcionado aos órgãos de TIC das IFES.
            Sendo assim, é uma oportunidade imperdível, não só para atualização sobre tendências
            e novas soluções disponíveis no mercado, como também de intercâmbio de informações e
            troca de experiências por entidades pares, que enfrentam missões e realidades muito
            semelhantes.
          </p>
          <p>
            Na edição de 2022, o encontro será realizado na capital sergipana e terá como tema <b>“As TICs como
              eixo de sustentação da estratégia das IFES”</b>. Na ocasião, poderão ser discutidos assuntos como
            estratégias e estudos de viabilidade técnica e econômica de implantação de inovações tecnológicas,
            alinhamento estratégico institucional, planos de transformação digital, redução de impacto ambiental
            e financeiro na operação dos serviços, privacidade e adaptação às mudanças de legislação, prevenção e
            tratamento de incidentes de segurança, dentre várias outras tantas possibilidades.
          </p>
          <p>
            É importante mencionar que, integrado ao cronograma do WTICIFES, acontecerá uma sessão específica para
            o Escritórios de Processos das IFES, momento para compartilhar conhecimentos e práticas referentes a gestão
            por processos tais como: análise, desenho, execução, monitoramento e controle, visando alcançar resultados
            relevantes e alinhados aos objetivos estratégicos das IFES.
          </p>
          <p>
            A TIC diante de seu papel estratégico para as organizações precisa se reinventar constantemente.
            Novos paradigmas e tecnologias surgem na velocidade em que inovações são demandadas pelos processos de
            negócios, como: Machine Learning, Big Data, Ciência de dados, IoT, LGPD, Governança, Segurança da
            Informação, Nuvem, Hiperconvergência, entre outras. Diante de um vasto multiverso de conceitos,
            aqueles que conduzem a criação e manutenção de soluções de TIC nas IFES precisam enxergar, de forma
            mais abrangente, o que é imprescindível e o que é aplicável nas suas instituições, dentro dos entraves,
            incertezas e diminuição de investimentos inerentes ao serviço público.
          </p>
          <p>
            A criação de novos cursos de graduação, a expansão cada vez maior dos cursos de pósgraduação, o
            crescimento no desenvolvimento de pesquisa e inovação e a oferta cada vez maior de serviços para a
            comunidade têm engrandecido o panorama de atuação das IFES ao longo dos anos. Para garantir a qualidade
            dessas ações com um cenário de recursos bem limitados, é necessário não somente focar o olhar em áreas
            estratégicas, como é a Tecnologia de Informação, mas fazer das TIC o eixo de sustentação da efetivação
            dos objetivos institucionais.
          </p>
        </div>
      </div>
    </div>
  );
}