import React from 'react';
import Modal from 'react-modal';

import "./styles.css";

import AracajuLogo from '../../assets/local_aracaju_logo.svg';
import OrlaAtalaia from '../../assets/orla_atalaia.png';
import Museu from '../../assets/museu.png';
import Mercado from '../../assets/mercado.png';
import Juninas from '../../assets/juninas.png';
import Croa from '../../assets/croa.png';
import OrlaSol from '../../assets/orla_sol.png';
import HospedagemPdf from '../../assets/HOSPEDAGEM_ARACAJU.pdf';
import PasseiosPdf from '../../assets/PASSEIOS_EM_SERGIPE.pdf';
import VidamHotel from '../../assets/vidam_hotel.png';
import Cacilda from '../../assets/cacilda.jpeg';
import Close from '../../assets/close-thick.svg';
import location_description from './location_description.json';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '900px',
    marginRight: '-50%',
    maxHeight: '80vh',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
};

Modal.setAppElement('#root');

function DescriptionModal(props: {
  img?: string;
  item: number;
  open: boolean;
  onClose: () => void;
}) {

  function closeModal() {
    props.onClose();
  }

  return (
    <Modal
      isOpen={props.open}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <img alt='Fechar modal' src={Close} onClick={closeModal} style={{ position: 'absolute', right: 10, top: 10 }} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img alt={location_description[props.item].title} src={props.img} className='img-modal' />
        <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>{location_description[props.item].title}</h3>
        <p>{location_description[props.item].description}</p>
      </div>
    </Modal>
  );
}

export default function Location(props: { refs: React.RefObject<HTMLHeadingElement> }) {
  const [modals, setModals] = React.useState({
    orla: false,
    museu: false,
    mercado: false,
    festas: false,
    croa: false,
    orlaPor: false,
  });

  return (
    <div ref={props.refs} style={{ padding: '64px 0px 0px 0px' }}>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'justify' }}>
          <h3 style={{ color: '#25408F', marginBottom: '32px', fontWeight: 'bold' }}>LOCAL</h3>
          <img alt="Aracaju Sergipe, a cidade da qualidade de vida." src={AracajuLogo} style={{ width: '100%', alignSelf: 'center' }}></img>

          <p style={{ marginTop: '16px' }}>
            Sergipe, o menor dos estados brasileiros, tem em Aracaju uma das capitais mais novas do
            Brasil, com apenas 166 anos de história. Apesar de nova, Aracaju é apontada como a capital
            com menor desigualdade no Nordeste brasileiro, também é vista como a capital com os
            hábitos de vida mais saudáveis do país. Aracaju foi uma cidade projetada e a sua marca
            principal são suas ruas geometricamente distribuídas como um tabuleiro de xadrez.
            Com seu clima sempre ensolarado, Aracaju é convidativa para desfrutar do seu longo
            litoral de águas mornas. Cidade tranquila, sem trânsito se comparada a outras capitais,
            Aracaju é margeada pelos rios Sergipe e Poxim, que proporcionam belas paisagens mesmo na
            área urbana. Em Aracaju podemos encontrar muito verde vindo de árvores, parques, mangues,
            zonas naturais e jardins. Pela noite a orla de Aracaju fervilha de turistas que disfrutam
            de bares, restaurantes, quiosques, ou mesmo de apenas uma boa caminhada.
          </p>

          <div className='row'>
            <div className='col-6 col-md-4 img-box' onClick={() => setModals({ ...modals, orla: true })}>
              <img className='img-100' alt="Orla de Atalaia" src={OrlaAtalaia}></img>
              <div className='img-description'>
                <span>Orla de Atalaia</span>
              </div>
            </div>
            <div className='col-6 col-md-4 img-box' onClick={() => setModals({ ...modals, museu: true })}>
              <img className='img-100' alt="Museu da Gente Sergipana" src={Museu}></img>
              <div className='img-description'>
                <span>Museu da Gente Sergipana</span>
              </div>
            </div>
            <div className='col-6 col-md-4 img-box' onClick={() => setModals({ ...modals, mercado: true })}>
              <img className='img-100' alt="Mercado Municipal" src={Mercado}></img>
              <div className='img-description'>
                <span>Mercado Municipal</span>
              </div>
            </div>
            <div className='col-6 col-md-4 img-box' onClick={() => setModals({ ...modals, festas: true })}>
              <img className='img-100' alt="Festas Juninas" src={Juninas}></img>
              <div className='img-description'>
                <span>Festas Juninas</span>
              </div>
            </div>
            <div className='col-6 col-md-4 img-box' onClick={() => setModals({ ...modals, croa: true })}>
              <img className='img-100' alt="Croa do Goré" src={Croa}></img>
              <div className='img-description'>
                <span>Croa do Goré</span>
              </div>
            </div>
            <div className='col-6 col-md-4 img-box' onClick={() => setModals({ ...modals, orlaPor: true })}>
              <img className='img-100' alt="Orla Por do Sol" src={OrlaSol}></img>
              <div className='img-description'>
                <span>Orla Por do Sol</span>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: '16px', flexDirection: 'column' }}>
          <p style={{ textAlign: 'left', fontSize: '16px' }}>O WTICIFES 2022 será realizado no Hotel Vidam, na orla de Aracaju.</p>
          <img className="img-100" alt="#" src={VidamHotel}></img>
          <p style={{ textAlign: 'left' }}>
            Participantes do evento tem 10% de desconto nas diárias.<br /><br />
            Algumas outras opções próximas ao hotel oficial do evento: <br /><br />

            Aquarius Praia Hotel - <a href='http://www.aquarioshotel.com.br/'>http://www.aquarioshotel.com.br/</a> <br />

            Real Classic Hotel - <a href='http://www.realclassichotel.com.br/'>http://www.realclassichotel.com.br/</a> <br />

            Real Praia Hotel - <a href='http://www.realpraiahotel.com.br/'>http://www.realpraiahotel.com.br/</a> <br />

            Hotel Pousada do Sol - <a href='http://www.hotelpousadadosol.com.br/'>http://www.hotelpousadadosol.com.br/</a> <br />

            Nascimento Praia Hotel - <a href='http://www.nascimentopraiahotel.com.br/'>http://www.nascimentopraiahotel.com.br/</a> <br />

            Novo Tropical Praia Hotel - <a href='http://www.tropicalpraiahotel.com.br/'>http://www.tropicalpraiahotel.com.br/</a> <br />

            Hotel da Costa - <a href='http://www.hoteldacosta.com.br/'>http://www.hoteldacosta.com.br/</a>
          </p>

          <img alt="#" src={Cacilda} style={{ width: 300, height: 'auto', alignSelf: 'center' }}/>

          <p style={{ textAlign: 'left' }}>
            <b>A Cacilda Aragão Tours é a agência de viagens oficial do evento.</b> <br />
            Clique <a download target="_blank" href={HospedagemPdf}>aqui</a> para ver as opções de hospedagem disponíveis. OBS: Diárias para o período de 05/07 à 07/07 (2 diárias) <br/>
            Clique <a download target="_blank" href={PasseiosPdf}>aqui</a> para ver os passeios disponibilizados pelo estado.
            
            
          </p>
        </div>

        <DescriptionModal item={0} img={OrlaAtalaia} open={modals.orla} onClose={() => setModals({ ...modals, orla: false })} />
        <DescriptionModal item={1} img={Museu} open={modals.museu} onClose={() => setModals({ ...modals, museu: false })} />
        <DescriptionModal item={2} img={Mercado} open={modals.mercado} onClose={() => setModals({ ...modals, mercado: false })} />
        <DescriptionModal item={3} img={Juninas} open={modals.festas} onClose={() => setModals({ ...modals, festas: false })} />
        <DescriptionModal item={4} img={Croa} open={modals.croa} onClose={() => setModals({ ...modals, croa: false })} />
        <DescriptionModal item={5} img={OrlaSol} open={modals.orlaPor} onClose={() => setModals({ ...modals, orlaPor: false })} />
      </div>
    </div>
  );
}