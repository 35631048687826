import React from 'react';

import "./styles.css";
import Logo from "../../assets/wticifes_logo.svg";
import MenuIcon from '../../assets/menu.svg';
import { useState, useEffect } from 'react';
import PDF from '../../assets/WTICIFES_2022_ANAIS.pdf';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function ItemDesktop(props: { direction: string, handleHeaderClick: (pos: number) => void }) {
    return (
        <div style={{ display: 'flex', flexDirection: props.direction as any }}>
            <span onClick={() =>props.handleHeaderClick(0)} className="header-item-container">LOCAL</span>
            <span onClick={() =>props.handleHeaderClick(1)} className="header-item-container">INSCRIÇÕES</span>
            <span onClick={() =>props.handleHeaderClick(2)} className="header-item-container">PALESTRANTES</span>
            <span onClick={() =>props.handleHeaderClick(3)} className="header-item-container">PROGRAMAÇÃO</span>
            <span onClick={() =>props.handleHeaderClick(4)} className="header-item-container">PATROCÍNIOS</span>
            <span className="header-item-container">
                <a style={{ textDecoration: 'none', color: 'white' }} download target="_blank" href={PDF}>ANAIS</a>
            </span>
        </div>
    );
}

interface HeaderProps {
    handleHeaderClick: (pos: number) => void;
}

export default function Header(props: HeaderProps) {
    const { width } = useWindowDimensions();
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="header-container">
            <div className="container">
                <div style={{ display: "flex", justifyContent: 'space-between', padding: '16px 0px', }}>
                    <div>
                        <img src={Logo} height='33' alt="Logo WTICIFES 2022" />
                    </div>
                    {
                        width < 992 && 
                        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
                            <img alt="Ícone de menu" src={MenuIcon} width={25} />
                        </div>
                    }
                    {width >= 992 && <ItemDesktop direction='row' handleHeaderClick={props.handleHeaderClick}/>}
                </div>
                {
                    menuOpen && width < 992  && <ItemDesktop direction='column' handleHeaderClick={props.handleHeaderClick} />
                }
            </div>
        </div>
    );
}