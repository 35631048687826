export default function Speakers(props: { refs: React.RefObject<HTMLHeadingElement> }) {
  return (
    <div ref={props.refs} style={{ paddingTop: '60px' }}>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>PALESTRANTES</h3>
          <h6 style={{ marginBottom: '32px', fontWeight: 'bold' }}>EM BREVE...</h6>
        </div>
      </div>
    </div>
  );
}