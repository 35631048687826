
export default function Inscriptions(props: { refs: React.RefObject<HTMLHeadingElement> }) {
  return (
    <div ref={props.refs} style={{ paddingTop: '60px' }}>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>INSCRIÇÕES</h3>
          <div style={{ display: 'inline-block' }}>
            As inscrições para o WTICIFES 2022 são gratuitas e exclusivamente para gestores de TI das universidades,
            autores de trabalhos aceitos e convidados da comissão. <br />
            Neste ano, as inscrições estão sendo pelo site Sympla por meio de convite individual por email. Se você
            se encaixa em alguns dos critérios envie um email para <a style={{ display: 'inline-block', flex: 0 }} href="mailto://wticifes2022@academico.ufs.br">wticifes2022@academico.ufs.br</a> e solicite o seu ingresso. <br />
            O Sympla enviará um email para a caixa postal indicada com o link para que possa fazer o cadastro.
            Não deixe de olhar também na caixa de spam.

          </div>
        </div>
      </div>
    </div>
  );
}