import "./styles.css";

export default function Speakers(props: { refs: React.RefObject<HTMLHeadingElement> }) {
  return (
    <div ref={props.refs} style={{ paddingTop: '60px' }}>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>PROGRAMAÇÃO GERAL</h3>

          <h4 className="heading">1º Dia - 05 de Julho</h4>
          <table className="cell-center width-100">
            <tr>
              <th>HORA</th>
              <th className="cell-center">AUDITÓRIO</th>
              <th >SALA VARANDAS</th>
            </tr>
            <tr>
              <td>17h</td>
              <td colSpan={2}>Abertura Oficial</td>
            </tr>
            <tr>
              <td>18h</td>
              <td colSpan={2}>Palestra Magna<br />
                Tendências em tecnologia para o mercado de educação superior<br />
                Nei Silverio – Consultor do Gartner</td>
            </tr>
            <tr>
              <td>19h</td>
              <td colSpan={2}>O papel da TI na transformação das IFES: 5 passos para um plano de ação<br />
                Wagner Alledo - Herval Soluções Corporativas
              </td>
            </tr>
            <tr>
              <td>20h-23h</td>
              <td colSpan={2}>Coquetel de Abertura</td>
            </tr>
          </table>

          <h4 className="heading">2º Dia - 06 de Julho</h4>
          <table className="cell-center width-100">
            <tr>
              <td>8h</td>
              <td colSpan={2}>Credenciamento</td>
            </tr>
            <tr>
              <td rowSpan={2}>9h</td>
              <td>Desenvolvimento de Sistemas (Chair: Valmir - UFPR)</td>
              <td>Escritório de Processos (Chair: Thiago Ventura - UFMT)</td>
            </tr>
            <tr>
              <td>Aplicando Lean Inception no MVP do App UFCSPA<br />
                Cristina Almeida da Silva, Alvaro Menezes, Roberto Rosa dos Santos, Deividi Moreira, Rosana Waszak<br /><br />

                Desafios na Implementação do Diploma Digital: Um Relato de Experiência na UFMG<br />
                Patrícia Silva, Daniele Zárate<br /><br />

                PlagDetect: Detecção de Autoplágio em Projetos de Pesquisa Utilizando Abordagem de Aprendizagem Profunda<br />
                Maria Alexandre, Lucas Maciel, Reginaldo dos Anjos, Allberson Dantas, Pedro Lemos, Renato Paiva</td>
              <td>Projeto de Modernização Administrativa da UFSM: Porque mapeamos todos os processos da instituição.<br />
                Evandro G. Flores, Frank Casado, Daniele M. Rizzetti, Jonas C. de Macedo, Taiani B. Kienetz, Rafael F. Neves<br /><br />

                Painel de indicadores como um sistema de auxílio à tomada de decisão: caso Almoxarifado Central<br />
                João Pedro Paim de Almeida, Gabriela Branco<br /><br />

                Adoção de uma central de serviços ágil na melhoria do processo de submissão de projetos acadêmicos em uma instituição federal do ensino superior brasileira<br />
                Josué Medeiros Júnior, Rosaneide Silva, Adson A. A. Dantas, André M. Gurgel, Thiago Oliveira</td>
            </tr>
            <tr>
              <td>10h</td>
              <td colSpan={2}>Coffee-Break</td>
            </tr>
            <tr>
              <td>10h30m</td>
              <td>Proteção de Dados - A Arquitetura Dell Technologies para Cyber Segurança
                Edvan Dors – PerfilComp Ltda</td>
              <td></td>
            </tr>
            <tr>
              <td>11h</td>
              <td>Multi-Cloud & Dell As a Service. A infraestrutura moderna nos seus termos<br />
                Vicente Lima - Channel SPSS LATAM (Storage Platforms Solutions Sales) - DELL</td>
              <td></td>
            </tr>
            <tr>
              <td rowSpan={2}>11h30m</td>
              <td>Infraestrutura Computacional (Chair: Valmir - UFPR)</td>
              <td>Gestão e Governança (Chair: Tereza Maciel - UFRPE)</td>
            </tr>
            <tr>
              <td>Inovação, Sustentabilidade e Transformação Digital de Processos Acadêmicos: Uma experiência na UFMG<br />
                Patrícia Silva <br /><br />

                Gestão de Conflitos através do Mapeamento de Modificações em Código-fonte <br />
                Andrei Queiroz, Renato Ribeiro, Andre Claret, Emanoel Junior<br /></td>
              <td>Análise de negócio e modelagem de processos no suporte de TI da UFMG<br />
                Sadallo Andere Neto<br /><br />

                Integração Automática de Documentos Nato-digitais em Processos de Negócio Suportados por Múltiplos Sistemas<br />
                Raquel Stasiu, Peter Perroni</td>
            </tr>
            <tr>
              <td>12h10m</td>
              <td colSpan={2}>Almoço Livre</td>
            </tr>
            <tr>
              <td rowSpan={2}>13h30m</td>
              <td>Desenvolvimento de Sistemas (Chair: Luiz Claudio - UFBA)</td>
              <td>Escritório de Processos (Chair: João Vieira - UFRGS)</td>
            </tr>
            <tr>
              <td>Gestão acadêmica na graduação da UFPR: desenvolvimento e implantantação de um sistema desenvolvido internamente <br />
                Rafaela Fontana, Lorena Kruger <br /><br />

                Software Prod+: Eficiência e Automatização na descoberta da produção intelectual UNIFESP<br />
                Alexsandro Cardoso Carvalho, Tiago Murakami, Virgínia Maria L. Leite, Lidiane Cristina Silva<br /><br />

                Uma Solução para Gestão de Acessos aos Restaurantes Universitários usando Dispositivos Móveis<br />
                Jonathan Santos, Marcos Vinícius dos Santos, Josimar Lima, Luis Eduardo de S. Santos, Marcos Dósea<br /></td>
              <td>Modelo matemático para priorização de processos: caso aplicado à UFPR<br />
                Aldemir Junglos, Alexandra Roeder<br /><br />

                Experiência de implementação de Secretarias Integradas Acadêmicas na Universidade Federal do Rio Grande do Norte <br />
                Luan Nascimento, Emanuela Justino, André Morais Gurgel<br /><br />

                Desenvolvimento da Arquitetura de Processos: caso UFPR<br />
                Aldemir Junglos, Alexandra Roeder, Amarílio Motta Floriano, Anne Cardoso<br /><br /></td>
            </tr>
            <tr>
              <td>14h30m</td>
              <td>O Projeto MECPlace e a Transformação Digital na Educação<br />
                Delson Silva – Ministério da Educação</td>
              <td></td>
            </tr>
            <tr>
              <td>15h30m</td>
              <td>SECURITY OPERATIONS CENTER: Universidade Segura e Inteligente<br />
                Wilson Piedade - CEO e Diretor Comercial do Oakmont Group</td>
              <td></td>
            </tr>
            <tr>
              <td>16h</td>
              <td colSpan={2}>Coffee Break</td>
            </tr>
            <tr>
              <td rowSpan={2}>16h30m</td>
              <td>Desenvolvimento de Sistemas (Chair: Apuena - UFRN)</td>
              <td>Escritório de Processos (Chair: Gabriela Branco - UFRGS)</td>
            </tr>
            <tr>
              <td>Implementando Requisitos de Usabilidade e Acessibilidade do Governo Federal no Portal Institucional Coronavirus<br />
                Rafael A. T. Barbosa, Rafael Meneses Santos, Uriel Marx Bispo, Marcos Dósea<br /><br />

                Controle de Assinatura de Documento Eletrônico<br />
                Peter Perroni, Eduardo Manika, Patricia Piccinini<br /><br />

                Metodologia de Desenvolvimento Multi-institucional de Plataforma para o Promover-Andifes<br />
                Giana Kroth, Andre Dantas<br /><br /></td>
              <td>O papel do Escritório de Projetos e Processos na transformação digital do processo de matrícula na UFMT<br />
                Cleiton Silva, Anne Cardoso, Thais F. B. da Silva, Greice Arruda, Thiago Ventura, Darclea Ubialli<br /><br />

                Gestão de Processos: Case Auxílio Permanência, Alimentação e Moradia UFMT<br />
                Cleiton Silva, Anne Cardoso, Thais F. B. da Silva, Greice Arruda, Thiago Ventura, Darclea Ubialli<br /><br />

                Implementação da Gestão de Processos em uma Instituição Federal de Ensino Superior: a trajetória da UFPA<br />
                Thiago Gonçalves, Huderson Melo<br /><br /></td>
            </tr>
            <tr>
              <td>17h30m</td>
              <td>Conectividade no Brasil e Construção de sistemas de informação compartilhados<br />
                Gustavo Balduíno - Andifes<br />
                Luiz Coelho - RNP<br /><br />

                Moderação: CGTIC
              </td>
              <td></td>
            </tr>
            <tr>
              <td>18h30m</td>
              <td colSpan={2}>Encerramento do Dia 2</td>
            </tr>
          </table>

          <h4 className="heading">3º Dia - 07 de Julho</h4>
          <table className="cell-center width-100">
            <tr>
              <td>8h</td>
              <td colSpan={2}>Credenciamento</td>
            </tr>
            <tr>
              <td rowSpan={2}>9h</td>
              <td>Desenvolvimento de Sistemas (Chair:Gustavo Kantorski - UFSM)</td>
              <td>Gestão e Governança (Chair: Lidiane - Unifesp)</td>
            </tr>
            <tr>
              <td>Aplicativo UFSM INTEGRA - UMA APLICAÇÃO MOBILE PARA VISUALIZAÇÃO DA EVASÃO NO ENSINO SUPERIOR <br />
                Raphael Miollo, Gustavo Kantorski<br /><br />

                Sistema de Informação e Gestão de Projetos - SIGPROJ<br />
                Jefferson Nobrega, Juvenal J. da S. Muniz, Maurilio Montanha<br /><br />

                Dados Estratégicos no Enfrentamento à Pandemia de COVID-19: Sistema MonitoraCOVID UFMG<br />
                Lucas Gomes, Patrícia Silva, Leandro Campos, Joao Pedro de Carvalho Castro<br /><br /></td>
              <td>Implantação do Google Workspace for Education na UFOP<br />
                Mariane Magalhães<br /><br />

                Implantação da área de aquisições de bens e contratações de serviços de TICs na UFSJ<br />
                Paulo de Almeida, Rodrigo Carvalho<br /><br />

                Indicadores de Gestão Universitária: estudo de caso da implementação do Portal de Indicadores na Universidade Federal de São Paulo<br />
                Alexsandro C. Carvalho, Virgínia M. L. Leite, Vanderlin Amorim P. Junior, Mauricio Palazzuoli, Lidiane C. Silva<br /><br /></td>
            </tr>
            <tr>
              <td>10h</td>
              <td colSpan={2}>Coffee-Break</td>
            </tr>
            <tr>
              <td>10h30m</td>
              <td>TCU: a Governança e Gestão de TIC nas Universidades Federais <br />
                Leandro Brum - Auditor do TCU</td>
              <td></td>
            </tr>
            <tr>
              <td>11h30m</td>
              <td>Palestra Patrocinador Teltec (30 min)</td>
              <td></td>
            </tr>
            <tr>
              <td>12h</td>
              <td colSpan={2}>Almoço Livre</td>
            </tr>
            <tr>
              <td rowSpan={2}>13h30m</td>
              <td>Administração de Sistemas (Chair: Luciano Gonda - UFMS)</td>
              <td>Gestão e Governança (Chair: Tereza Maciel - UFRPE)</td>
            </tr>
            <tr>
              <td>PREDE - Um relato de experiência na implantação de processos eletrônicos da UFPB<br />
                Francisco Batista, Danilo Alexandre Araújo, Ramon Celeste Ramos<br /><br />

                Single Sign-On UTFPR: Sistema Único de Autenticação para Aplicações Heterogêneas<br />
                Peter Perroni, Marcelo Faveri, Eduardo Manika, Wendel Oliveira<br /><br />

                Plataforma Integrada de Ensino Remoto para a UFPel: Ambiente e-AULA<br />
                Eduardo Monks, Tiago Al-Alam, Pablo Rosa, Jerônimo Feijó Noble da Rosa, Henrique Rippel<br /><br /></td>
              <td>Novas atividades profissionais no contexto da Governança Digital e suas primeiras repercussões no dimensionamento do Quadro de Pessoal de TIC da UFPEL <br />
                Francisco Rodrigues, Alfredo Tillmann, José Hiram Salengue Noguez, Eduardo Maroñas Monks<br /><br />

                Relato UFBA: sediar o Ponto de Presença da RNP na Bahia uma decisão estratégica com desdobramentos positivos<br /><br />
                Adriana Ribeiro, Thiago Bomfim, Claudete Alves, Luiz Cláudio Mendonça<br />

                Um Processo Ágil para Mapeamento de Processos em uma Organização Púbica<br />
                Estelamaris Pina, Dryele Nascimento, Marcos Dósea<br /><br /></td>
            </tr>
            <tr>
              <td rowSpan={2}>14h30m</td>
              <td>Administração de Sistemas (Chair: Taciano Alcântara - UFCA)</td>
              <td>Gestão e Governança (Chair: Júlio Mattos - UFPEL)</td>
            </tr>
            <tr>
              <td>SigEleição customizações: Um relato de experiência na Eleição 2021 para reitoria da Ufopa. <br />
                Kleison Silveira Paiva, Antônio Fabrício Guimarães De Sousa, Valkir Santos Da Silva<br /><br />

                Sistema de Múltiplas Instâncias para Reserva de Recursos<br />
                Túlio Rodrigues, Gray Farias<br /><br /></td>
              <td>Uma Proposta de Modelo de Desenvolvimento de Governança e Gestão de TIC Baseado em Objetivos<br />
                Andre Dantas, Erica Esteves Cunha de Miranda<br /><br />

                Comportamento Organizacional e Aplicação de Gestão de Pessoas na Superintendência de Tecnologia da Informação da UFRN<br />
                Jéssica Carvalho, Apuena Gomes<br /><br /></td>
            </tr>
            <tr>
              <td>15h10m</td>
              <td>Conheça o Adobe Substance 3D a principal ferramenta para criação do seu Metaverso<br />
                Rodrigo Rocha e Ricardo Siqueira<br />
                Espaço MCR / Adobe
              </td>
              <td></td>
            </tr>
            <tr>
              <td>15h40m</td>
              <td colSpan={2}>Coffee Break</td>
            </tr>
            <tr>
              <td rowSpan={2}>16h10m</td>
              <td>Administração de Sistemas (Chair: Taciano Alcântara - UFCA)</td>
              <td>Gestão e Governança (Chair: Júlio Mattos - UFPEL)</td>
            </tr>
            <tr>
              <td>Cara-Crachá@UFSC: Sistema de autorização de acesso agnóstico à aplicações<br />
                Guilherme Geronimo, José Norberto Guiz Fernandes Corrêa<br /><br />

                Estratégia de otimização para manipulação de dados em ambientes complexos utilizando uma solução gratuita<br />
                Rogerio Rodrigues Carvalho, Victor Gonçalves Bento, Ricardo Borges, Bruno Machado, Micael Mello<br /><br /></td>
              <td>Estruturação e Implantação da Central de Serviços Digitais na UFRPE <br />
                Renato Mendes, Teresa Maciel <br /><br />

                Estratégia de Transformação Digital da UFRPE<br />
                Teresa Maciel<br /><br /></td>
            </tr>
            <tr>
              <td>16h50m</td>
              <td>Liberdade de escolha para a TI em um mundo multicloud – Continuidade de negócios com segurança e simplicidade de gestão <br />
                Fábio Souza – Gerente Comercial – Nutanix Brasil<br /><br /></td>
              <td></td>
            </tr>
            <tr>
              <td>17h20m</td>
              <td>Palo Alto Networks – Trends & Strategy<br />
                Vitor Vale – Regional Sales Manager – Palo Alto
              </td>
            </tr>
            <tr>
              <td>17h50m</td>
              <td>Premiação Best Paper - WTICIFES</td>
            </tr>
            <tr>
              <td>18h30m</td>
              <td colSpan={2}>Sorteio de brindes no auditório <br />
                Solenidade de fechamento do evento</td>
            </tr>
            <tr>
              <td>19h30m - 21h</td>
              <td colSpan={2}>Coquetel de Encerramento</td>
            </tr>
          </table>

          <h4 className="heading">4º Dia - 08 de Julho</h4>
          <table className="cell-center width-100">
            <tr>
              <td>8h30m</td>
              <td colSpan={2}>Plenária CGTIC (Colégio dos Gestores de TIC das IFES)</td>
              <td></td>
            </tr>
            <tr>
              <td>12h30m</td>
              <td colSpan={2}>Encerramento da Plenária</td>
              <td></td>
            </tr>
          </table>


        </div>
      </div>
    </div>
  );
}