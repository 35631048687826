import HowToSponsorImg from "../../assets/how_to_sponsor.png";

function BackgroundText(props: { text: string }) {
  return (
    <div style={{
      display: 'inline-block',
      backgroundColor: '#00005e',
      width: 'fit-content',
      color: '#fff',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 'bold'
    }}>
      <span>{props.text}</span>
    </div>
  );
}

export default function HowToSponsor() {
  return (
    <div style={{ paddingTop: '60px' }}>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>PATROCÍNIO WTICIFES 2022</h3>
          <p>
            Estar presente no XIV WTICIFES é uma oportunidade de estabelecer relacionamento direto
            com a maioria das 69 universidades federais do Brasil, permitindo que os gestores de TIC
            dessas instituições ampliem seu conhecimento acerca do portfolio dos produtos e/ou serviços
            da sua empresa.
          </p>
          <p>
            A segmentação do público e o ambiente voltado para a temática das TICs é propício para
            a formação de networking com agentes que tem o poder de decisão nas IFES. É uma
            oportunidade de negócios que não pode ser desperdiçada.
          </p>
          <BackgroundText text="Veja as modalidades de patrocínio e conheca quais os benefícios disponíveis:" />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '32px 0px' }}>
            <img style={{ maxWidth: '800px', width: '100%' }} alt="Como patrocinar" src={HowToSponsorImg} />
          </div>
          <BackgroundText text="Observações importantes:" />
          <div style={{ margin: '16px 0px', fontSize: '18px' }}>
              1. Os estantes previstos nos planos de patrocínio serão distribuídos conforme a ordem de
              fechamento dos contratos;<br/>
              2. Caso sua empresa deseje alguma coisa mais específica para nosso público entre em contato
              e faça a sua proposta.
          </div>
        </div>
      </div>
    </div>
  );
}