
export default function WorkCall() {
    return (
        <div style={{ paddingTop: '32px' }}>
            <div className='container'>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                    <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>CHAMADA DE TRABALHOS</h3>
                    <h5 style={{ fontWeight: 'bold' }}>XIV WTICIFES</h5>
                    <p>
                        Convidamos a comunidade de Tecnologia da Informação e Comunicação das IFES a submeterem seus trabalhos
                        de pesquisa, de desenvolvimento e de resultados experimentais nas IFES, abrangendo um ou mais dos seguintes temas:
                    </p>
                    <ul>
                        <li>Segurança da Informação</li>
                        <li>Gestão e Governança de TI</li>
                        <li>Administração de Sistemas</li>
                        <li>Desenvolvimento de Sistemas</li>
                        <li>Infraestrutura Computacional</li>
                        <li>Conectividade</li>
                        <li>Escritório de processos</li>
                    </ul>
                    <h6 style={{ fontWeight: 'bold' }}>Datas Importantes</h6>
                    <p>
                        <strong>Submissão:</strong> <s>até 02/05/2022</s> <ins style={{ color: 'red', textDecoration: 'none' }}>Prazo final dia 06/05/2022</ins><br />
                        <strong>Divulgação dos resultados:</strong> 05/06/2022 <br/>
                        <strong>Inscrição:</strong> 06/06/2022 até a data de evento<br />
                        <strong>Evento:</strong> 05 a 07 de julho
                    </p>
                    <h6 style={{ fontWeight: 'bold' }}>Instruções para Submissão dos Artigos</h6>
                    <ul>
                        <li>Recomendamos a seguinte estrutura para o artigo:</li>
                    </ul>
                    <ol>
                        <li>Resumo, palavras-chave</li>
                        <li>Introdução</li>
                        <li>Métodos</li>
                        <li>Resultados</li>
                        <li>Conclusão</li>
                        <li>Referências</li>
                    </ol>
                    <p>O resumo e as palavras-chave em inglês são opcionais.</p>
                    <ul>
                        <li>Devem estar de acordo com o padrão da SBC (Sociedade Brasileira de Computação) – templates disponíveis no <a href="https://www.sbc.org.br/documentos-da-sbc/summary/169-templates-para-artigos-e-capitulos-de-livros/878-modelosparapublicaodeartigos">link</a></li>
                        <li>Deve ser escrito em português ou inglês</li>
                        <li>Deve conter entre 3 a 5 páginas</li>
                        <li>Deve ser submetido no formato PDF no sistema JEMS, acesse&nbsp;<a href="https://submissoes.sbc.org.br/home.cgi?c=4101">https://submissoes.sbc.org.br/home.cgi?c=4101</a></li>
                        <li>Deve ser submetido no formato PDF no sistema JEMS, o Termo de Autorização de Publicação, disponível neste &nbsp;<a href="https://www.sbc.org.br/documentos-da-sbc/summary/164-publicacoes/1231-termo-de-autorizacao-de-publicacao">link</a></li>
                        <li>Os trabalhos serão avaliados e selecionados pelo Comitê Científico do evento observando os seguintes critérios:</li>
                    </ul>
                    <ol>
                        <li>Relevância (enquadramento do artigo)</li>
                        <li>Originalidade</li>
                        <li>Mérito técnico</li>
                        <li>Apresentação (conteúdo com forma)</li>
                        <li>Organização</li>
                    </ol>
                    <ul>
                        <li>Os três melhores artigos avaliados serão premiados ao final do evento</li>
                    </ul>
                    <p>Para mais informações entre em contato com a Coordenação do Comitê Científico</p>
                    <h5 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>COMITÊ CIENTÍFICO</h5>

                    <h6 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>Coordenadoras</h6>
                    <p>Dra. Lígia Maria Carvalho Sousa – UNILAB – email: <a href="mailto:ligia@unilab.edu.br">ligia@unilab.edu.br</a><br/>
                    Dra. Eunice Pereira dos Santos Nunes - UFMT – email: <a href="mailto:eunice@ufmt.br">eunice@ufmt.br</a></p>
                </div>
            </div>
        </div>
    );
}