import React from 'react';

import "./styles.css";
import LocalDescription from '../../assets/front_content_description.svg';
import ChevronDown from '../../assets/chevron-down.svg';
import UfsLogo from '../../assets/ufs-horizontal.svg';
import Countdown from 'react-countdown';

// 992px
export default function FrontContent() {
    return (
        <div className='front-container'>
            <div className="container inner-container">
                <div className='inner-responsive-box'>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <div className='front-panel-title'>
                            As TICs como eixo de sustentação da estratégia das IFES
                        </div>
                        <img style={{ alignSelf: 'flex-start', width: '100%', maxHeight: '500px', maxWidth: '600px'  }} alt='Aracaju - SE, 08, 09 e 10 de junho de 2022' src={LocalDescription}></img>
                    </div>
                    <div className='right-container'>
                        <div>
                            <div style={{ textAlign: 'left' }}>Tempo para início do evento:</div>
                            <Countdown 
                                date={'07/05/2022'}
                                renderer={({ days, hours, minutes, seconds }) => (
                                    <div className='timer-small'>
                                        <strong className='timer-strong'>{formatNumberAddZero(days)}</strong>D : <strong className='timer-strong'>{formatNumberAddZero(hours)}</strong>H : <strong className='timer-strong'>{formatNumberAddZero(minutes)}</strong>M : <strong className='timer-strong'>{formatNumberAddZero(seconds)}</strong>S
                                    </div>
                                )}/>
                        </div>
                        <img alt='Logo da UFS' width={'100%'} style={{ maxWidth: 300 }}src={UfsLogo} />
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: 5, left: 0, right: 0 }}>
                    <img alt='Mais conteúdo' height={50} width={50} color="#FFF" src={ChevronDown}/>
                </div>
            </div>
        </div>
    );
}

function formatNumberAddZero(number: number) {
    return number > 9 ? number : `0${number}`;
}