import "./styles.css";

const cache = {};

function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context("../../assets/sponsors", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map(module => module[1] as string);

export default function Sponsorships(props: { refs: React.RefObject<HTMLHeadingElement> }) {
  const sponsors = images.map((image, index) => (
    <>
      <img className='img-sponsor' alt={image} src={image} />
      { (index + 1 % 3 === 0 && index !== 0) && <br/> }
    </>
  ));

  return (
    <div ref={props.refs} style={{ paddingTop: '60px' }}>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <h3 style={{ color: '#25408F', marginBottom: '16px', fontWeight: 'bold' }}>PATROCINADORES CONFIRMADOS</h3>
          <div className='container'>
            { sponsors }
          </div>
        </div>
      </div>
    </div>
  );
}