import './App.css';
import React from 'react';
import { useRef } from 'react';
import Header from './components/Header/Header';
import FrontContent from './components/FrontContent/FrontContent';
import Presentation from './components/Presentation';
import Realization from './components/Realization';
import Location from './components/Location';
import Footer from './components/Footer';
import Inscriptions from './components/Inscriptions';
import Speakers from './components/Speakers';
import Sponsorships from './components/Sponsorships';
import HowToSponsor from './components/HowToSponsor';
import Contact from './components/Contact';
import WorkCall from './components/WorkCall';
import Schedule from './components/Schedule';

// const cache = {};

// function importAll(r) {
//     r.keys().forEach((key) => (cache[key] = r(key)));
// }
// // Note from the docs -> Warning: The arguments passed to require.context must be literals!
// importAll(require.context("./assets", false, /\.(png|jpe?g|svg)$/));

// const images = Object.entries(cache).map(module => module[1] as string);

function App() {
  const locationRef = useRef<HTMLHeadingElement>(null);
  const inscritionsRef = useRef<HTMLHeadingElement>(null);
  const speakersRef = useRef<HTMLHeadingElement>(null);
  const scheduleRef = useRef<HTMLHeadingElement>(null);
  const sponsorsRef = useRef<HTMLHeadingElement>(null);
  const refs = [
    locationRef,
    inscritionsRef,
    speakersRef,
    scheduleRef,
    sponsorsRef
  ]

  const handleHeaderClick = (pos: number) => {
    refs[pos].current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    
    <div className="App">
      {/* {images.map(image => (
                <img style={{width: 100}} src={image} />
            ))} */}
      <Header handleHeaderClick={handleHeaderClick} />
      <FrontContent />
      <Presentation />
      <Realization />
      <Location refs={locationRef}/>
      <Inscriptions refs={inscritionsRef}/>
      <WorkCall />
      <Speakers refs={speakersRef}/>
      <Schedule refs={scheduleRef}/>
      <Sponsorships refs={sponsorsRef}/>
      <HowToSponsor />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
