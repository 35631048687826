import React from 'react';
import "./styles.css";

import RealizationImg from '../../assets/realizacao.png';

export default function Realization() {
  return (
    <div style={{ backgroundColor: '#00005e', display: "flex", marginTop: "64px" }}>
      <div className='container'>
        <div className='realization-inner-container'>
          <div className='container'>
            <div style={{ display: 'flex', flexDirection: 'column',  }}>
              <h3 style={{ color: '#25408F', marginBottom: '32px', fontWeight: 'bold' }}>REALIZAÇÃO</h3>
              <p>
                A Universidade Federal de Sergipe, fundada em 1968, tem em seus 53 anos de anos
                o reconhecimento pela excelência no ensino pesquisa e extensão. Na edição de 2021
                a Times Higher Education, considerou a UFS como a 7ª melhor universidade do Brasil.
                Motivo de muito orgulho da comunidade sergipana. Atualmente, a UFS possui em torno
                de 30 mil discentes distribuídos nos cursos de graduação e pós-graduação. Entre os
                servidores, temos em torno de 2000 docentes, sendo 75% doutores, e aproximadamente
                1500 técnicos administrativos.
              </p>
              <p>
                A Superintendência de Tecnologia de Informação (STI), órgão auxiliar da reitoria
                de Universidade Federal de Sergipe, tem como objetivo prover produtos e serviços
                de Tecnologia de Informação e Comunicação para a toda comunidade administrativa e
                acadêmica da UFS, apoiando o desenvolvimento do ensino, pesquisa e extensão, alinhado
                com as diretrizes estratégicas institucionais, o Plano de Desenvolvimento Institucional,
                os planos e políticas específicas de TIC e as recomendações emanadas do Comitê de
                Governança Digital (CGD).
              </p>
              <div style={{ alignSelf: 'center' }}>
                <img alt='Campus da UFS' style={{ maxHeight: '500px', maxWidth: '100%', width: 'auto' }} src={RealizationImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}